<template>
    <div>
        <table :class="`w-50 table__border--default`">
            <thead :class="`table__head--default`">
                <th style="min-width: 150px;">Naam</th>
                <th style="min-width: 150px">Voorraad rotatie*</th>
                <th style="min-width: 150px;">Gem. stadagen</th>
                <th style="min-width: 150px;">Hoeveelheid P</th>
                <th style="min-width: 150px;">Hoeveelheid S</th>
                <th style="min-width: 200px;">Verkocht afgelopen week</th>
            </thead>
            <tbody>
                <tr v-for="(item, key) in table_data" :key="key">
                    <td v-if="item.drillDownURL == 'purchaseduostructure'"><router-link
                            :to="{ name: `${item.drillDownURL}`, params: { bu: bu, kind: item.kind } }">{{ key
                            }}</router-link></td>
                    <td v-else-if="item.drillDownURL == 'purchaseduobrand'"><router-link
                            :to="{ name: `${item.drillDownURL}`, params: { bu: bu, kind: item.kind, structure: (item.structure == '') ? 'Overig' : item.structure } }">{{
                            key
                            }}</router-link></td>
                    <td v-else-if="item.drillDownURL == 'purchaseduoitems'"><router-link
                            :to="{ name: `${item.drillDownURL}`, params: { bu: bu, kind: item.kind, structure: (item.structure == '') ? 'Overig' : item.structure, brand: item.brand } }">{{
                            key
                            }}</router-link></td>
                    <td class="text-center">{{ item.rotatie }}</td>
                    <td class="text-center">{{ item.average_stagedays }}</td>
                    <td class="text-center">{{ item.amount_in_p }}</td>
                    <td class="text-center">{{ item.amount_in_s }}</td>
                    <td class="text-center">{{ item.amount_v_last_week }}</td>
                </tr>
            </tbody>
            <tfoot v-if="table_data_totals">
                <th>Totaal:</th>
                <th class="text-center">{{ table_data_totals.rotatie }}</th>
                <th class="text-center">{{ table_data_totals.average_stagedays }}</th>
                <th class="text-center">{{ table_data_totals.amount_in_p }}</th>
                <th class="text-center">{{ table_data_totals.amount_in_s }}</th>
                <th class="text-center">{{ table_data_totals.amount_v_last_week }}</th>
            </tfoot>
        </table>
        <strong>* Hoeveel keer de voorraad verkocht wordt per jaar.</strong>
        <p class="center">Omloopsnelheid steefgetal: <strong>{{ streefgetal }}</strong></p>
    </div>
</template>

<script>
export default {
    props: {
        table_data: [Object, Array],
        table_data_totals: [Object, Array],
        bu: [String]
    },
    data: () => ({
        streefgetal: null,
    }),
    created() {
        if (this.bu == 200) {
            this.streefgetal = "4.54"
        } else if (this.bu == 202) {
            this.streefgetal = "4.53"
        } else {
            this.streefgetal = "7.08"
        }
    }

}
</script>
